import i18n from '../i18n/index';

/* eslint-disable no-useless-escape */
const validate = {
  email: (email: string) => {
    //Validates the email address
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  },
  phone: (phone: string, countryLimit = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]) => {
    const phoneRegex = new RegExp(`^\\d{${countryLimit.join('}|\\d{')}}$`);
    return phoneRegex.test(phone);
  },
  length: (string: string, min: number, max: number, optional = false) => {
    if (string == null || string == undefined) {
      return;
    }

    if (optional && string.length == 0) {
      return true;
    }

    return string.length >= min && string.length <= max;
  },
  expired: (expire: number) => {
    const timeNow = new Date().getTime();
    return timeNow > expire;
  },
  isNumber: (number: number | string) => {
    return !isNaN(Number(number));
  },
  password: (password: string) => {
    // Check empty
    if (password == '') {
      return i18n.t('password.passwordEmpty');
    }

    // Minimum length
    if (password.length < 6) {
      return i18n.t('password.passwordMustBeAtLeast6Characters');
    }

    // Characters
    if (!/[A-Za-z]/.test(password)) {
      return i18n.t('password.passwordMustContainAtLeastOneCharacter');
    }

    // Numbers
    if (!/\d/.test(password)) {
      return i18n.t('password.passwordMustContainAtLeastOneNumeric');
    }
    return true;
  },
  date: (date: Date) => {
    return date instanceof Date && !isNaN(date.getTime());
  }
};

export default validate;
