import Skin from 'types/Skin';

const ksu247: Skin = {
  COLORS: {
    PRIMARY: '#91011e',
    PRIMARY_DARK: '#40000c',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#40000c',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/09/ksu247-icon.png',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/09/ksu247-logo-hvit-tekst.png',
  },
};

export default ksu247;