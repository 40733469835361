import { AxiosError } from 'axios';
import useUser from 'hooks/useUser';
import routes from 'navigation/routes';
import { FC, useState } from 'react';
import { SubmitHandler, useForm, Validate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { validate } from 'utils';
import useAxiosAlert from 'hooks/useAxiosAlert';
import userAuthService from 'services/user-auth.service';
import logger from 'logger/logger';
import { PageContainer } from 'components';
import PaperCard from 'components/PaperCard/PaperCard';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import useQuery from 'hooks/useQuery';

type SetNewPasswordParameters = {
  token: string;
  phone?: string;
};

type FormValues = {
  password: string;
  repeatPassword: string;
  phone?: string;
};

type View = {
  loading?: boolean;
  form?: boolean;
  success?: boolean;
  errorMessage?: string;
  error?: boolean;
};

const SetNewPassword: FC = () => {
  // Get phone parameter from query string
  const query = useQuery();
  const phone = query.get('phone');
  const setPhone = phone === '1' || phone === 'true';
  const queryParams = new URLSearchParams(location.search);
  const fromApp = queryParams.get('fromApp');

  const { token } = useParams<SetNewPasswordParameters>();

  const { t } = useTranslation();
  const { signOut } = useUser();
  const navigate = useNavigate();
  const axiosAlert = useAxiosAlert();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<FormValues>();

  const [view, setView] = useState<View>({
    loading: false,
    form: true,
    success: false,
    errorMessage: null,
    error: false
  });

  function isMobileDevice() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  const updateView = (updatedView: View) => {
    setView({
      ...{
        loading: false,
        form: false,
        success: false,
        errorMessage: null,
        error: false
      },
      ...updatedView
    });
  };

  const onSubmit: SubmitHandler<FormValues> = async (fieldData) => {
    updateView({
      loading: true
    });

    try {
      if (setPhone) {
        // Set new password and phone will work for newly importe users only
        await userAuthService.setNewPasswordAndPhone(fieldData.password, token, fieldData.phone);
      } else {
        await userAuthService.setNewPassword(fieldData.password, token);
      }
      updateView({
        success: true
      });
    } catch (error) {
      logger.warn('Set new password failed', error);
      axiosAlert(error as AxiosError, [
        {
          status: 401,
          cb: () =>
            updateView({
              error: true,
              errorMessage: t('alerts.invalidLink'),
              loading: false
            })
        },
        {
          status: 403,
          cb: () =>
            updateView({
              error: true,
              errorMessage: t('alerts.userNotAuthorizedForPhoneChange'),
              loading: false
            })
        },
        {
          status: 409,
          cb: () =>
            updateView({
              error: true,
              errorMessage: t('alerts.duplicatePhoneContactSupport'),
              form: true,
              loading: false
            })
        }
      ]);
    }
  };

  const passwordsMustMatch = (repeatPassword: string) => {
    const password = getValues('password');
    if (password !== repeatPassword) {
      return t('password.passwordsMustMatch');
    }
    return true;
  };

  const handleNavigationToLogin = () => {
    signOut();
    if (fromApp && isMobileDevice()) {
      window.location.href = `${fromApp}://login`;

      setTimeout(() => {
        navigate(routes.LOGIN.path);
      }, 2000);
    } else {
      navigate(routes.LOGIN.path);
    }
  };

  return (
    <PageContainer centerHorizontal centerVertical footerLight pageCard>
      <PaperCard pageCard>
        <LogoPaperHeader link />
        <PaperContent>
          <Stack spacing={2}>
            {view.form && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={1.5}>
                  {setPhone && (
                    <>
                      <Typography variant='h5' className='page-title'>
                        {t('password.setNewPhone')}
                      </Typography>
                      <Alert severity='info'>{t('password.setNewPhoneExplenation')}</Alert>
                      <TextField
                        label={t('common.phone')}
                        type={'text'}
                        {...register('phone', {
                          validate: validate.phone as unknown as Validate<string, FormValues>,
                          required: true
                        })}
                        error={errors.phone ? true : false}
                        helperText={errors.phone ? errors.phone.message : ''}
                      />
                    </>
                  )}
                  <Typography variant='h5' className='page-title'>
                    {t('password.setNewPasswordExplenation')}
                  </Typography>
                  <TextField
                    label={t('password.newPassword')}
                    type={'password'}
                    {...register('password', {
                      validate: validate.password,
                      required: true
                    })}
                    error={errors.password ? true : false}
                    helperText={errors.password ? errors.password.message : ''}
                  />
                  <TextField
                    label={t('password.repeatPassword')}
                    type={'password'}
                    {...register('repeatPassword', {
                      validate: passwordsMustMatch,
                      required: true
                    })}
                    error={errors.repeatPassword ? true : false}
                    helperText={errors.repeatPassword ? errors.repeatPassword.message : ''}
                  />
                  <Button type='submit' variant='contained'>
                    {t('password.setNewPassword')}
                  </Button>
                </Stack>
              </form>
            )}
            {view.error && <Alert severity='error'>{view.errorMessage}</Alert>}
            {view.success && (
              <Stack gap={2}>
                <Alert severity='success'>
                  <Typography>{t('password.wasSuccessfullySet')}</Typography>
                </Alert>
                <Button onClick={handleNavigationToLogin} variant='contained'>
                  {t('common.goToLogin')}
                </Button>
              </Stack>
            )}
            {view.loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress sx={{ margin: '10px 0' }} />
              </div>
            )}
          </Stack>
        </PaperContent>
        <PaperFooter>
          <Button onClick={handleNavigationToLogin}>{t('navigation.backToLogin')}</Button>
        </PaperFooter>
      </PaperCard>
    </PageContainer>
  );
};

export default SetNewPassword;
