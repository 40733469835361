import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validate } from 'utils';
import useDevice from 'hooks/useDevice';
import CountryCode, { COUNTRY_INFO } from 'types/CountryCode';
import { getCountryFlag } from 'utils/_getCountryCodeFlag';
import { useEffect, useRef } from 'react';
import theme from 'styles/theme';

type PhoneNumberInputProps = {
  focusInput: boolean;
  formSubmitted: boolean;
  fieldName: string;
};

const PhoneNumberInputCountry: React.FC<PhoneNumberInputProps> = ({
  focusInput,
  formSubmitted,
  fieldName
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const { setValue, getValues, formState, register, watch } = useFormContext();
  const country = watch('phoneCountryCode');
  const countryInfo = COUNTRY_INFO[(country as keyof typeof COUNTRY_INFO) || 'NO'];

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputEvent = event.nativeEvent as InputEvent;
    const cleanedValue = event.target.value.replace(/\D/g, ''); // Remove non-digit characters

    const phoneCountryMax = countryInfo.phoneDigits;

    if (
      cleanedValue.trim().length >= phoneCountryMax[phoneCountryMax.length - 1] &&
      inputEvent.inputType !== 'deleteContentBackward'
    ) {
      setValue(fieldName, cleanedValue.slice(0, phoneCountryMax[phoneCountryMax.length - 1]));
      return;
    }

    setValue(fieldName, cleanedValue);
  };

  useEffect(() => {
    if (focusInput) {
      inputRef.current?.focus();
    }
  }, [focusInput, fieldName]);

  const sortedCountryCodes = Object.keys(COUNTRY_INFO).sort((a, b) => {
    const codeA = Number(COUNTRY_INFO[a as keyof typeof COUNTRY_INFO].phonePrefix);
    const codeB = Number(COUNTRY_INFO[b as keyof typeof COUNTRY_INFO].phonePrefix);
    return codeA - codeB;
  });

  return (
    <FormControl
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <IconButton
          color='primary'
          sx={{
            p: '0',
            pl: '4px'
          }}
          aria-label='directions'
        >
          <Select
            {...register('phoneCountryCode', { required: true })}
            defaultValue={'NO'}
            value={country}
            disabled={formSubmitted}
            sx={{
              width: '95px',
              borderColor: theme.palette.primary.main,
              borderRight: 0,
              flex: '0 0 100px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                marginInlineEnd: '0 !important',
                marginInlineStart: '0 !important',
                borderRight: '1px solid rgba(0,0,0,0)'
              },
              borderRadius: '50px',

              borderTopRightRadius: '0',
              borderBottomRightRadius: '0'
            }}
            MenuProps={{
              sx: {
                ['.MuiPaper-root']: {
                  margin: 0,
                  borderRadius: 0
                }
              }
            }}
            error={formState.errors?.[fieldName] ? true : false}
          >
            {sortedCountryCodes.map((countryCode, index) => (
              <MenuItem key={index} value={countryCode}>
                {getCountryFlag(countryCode as CountryCode)}
                {COUNTRY_INFO[countryCode as keyof typeof COUNTRY_INFO].phonePrefix}
              </MenuItem>
            ))}
          </Select>
        </IconButton>

        <TextField
          id={fieldName}
          inputRef={inputRef}
          autoComplete={device === 'mobile' ? 'off' : 'username'}
          {...register(fieldName, {
            required: true,
            validate: () => {
              const phoneCountryMax = countryInfo.phoneDigits;

              return validate.phone(getValues(fieldName).replace(/\D/g, ''), phoneCountryMax)
                ? true
                : t('alerts.phoneNumberNotValid').toString();
            }
          })}
          onChange={handleChange}
          sx={{
            borderRadius: '50px',

            // paddingleft 11.5px on input
            marginLeft: '2.7px',

            flex: 1,
            '&.Mui-focused': {
              border: 0
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0'
            },
            '&:focus': {
              outline: 'none'
            },
            '&:focus-visible': {
              outline: 'none'
            },
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.light} inset` // Match autofill background
            }
          }}
          type={'tel'}
          placeholder={t('login.phoneNumber') + ' *'}
          className={'field phone-number-input username'}
          disabled={formSubmitted}
          error={formState.errors?.[fieldName] ? true : false}
        />
      </Box>

      <Typography
        sx={{
          fontSize: '0.99rem',
          color: theme.palette.error.main
        }}
      >
        {formState.errors?.[fieldName] ? formState.errors?.[fieldName].message.toString() : ''}
      </Typography>
    </FormControl>
  );
};

export default PhoneNumberInputCountry;
