import { Edit } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AxiosError } from 'axios';
import PhoneNumberInputCountry from 'components/PhoneNumber/PhoneNumberCountry';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user.service';

type PhoneNumberFormProps = {
  onSubmit: (
    isNewUser: boolean,
    preSentData: {
      phoneNumber: string;
      phoneCountryCode: string;
    }
  ) => void;
  onFormReset: () => void;
};

type Fields = {
  username: string;
  phoneCountryCode: string;
  phone: string;
};

const PhoneNumberForm: FC<PhoneNumberFormProps> = ({ onSubmit: onSubmitProp, onFormReset }) => {
  const { t } = useTranslation();
  const formMethods = useForm<Fields>();
  const axiosAlert = useAxiosAlert();
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const phoneNumberVerificationHandler = async (
    phoneNumber: string,
    phoneCountryCode: string
  ): Promise<boolean> => {
    const res = await userService.phoneNumberExist(phoneNumber, phoneCountryCode);
    const { data: phoneNumberExists } = res;
    return phoneNumberExists;
  };

  const onSubmit = async (data: Fields) => {
    try {
      setLoading(true);
      const { username: phone, phoneCountryCode } = data;
      const isNewUser = !(await phoneNumberVerificationHandler(phone, phoneCountryCode));
      setFormSubmitted(true);
      onSubmitProp(isNewUser, { phoneNumber: phone, phoneCountryCode });
    } catch (error) {
      logger.error('Phone number verification failed');
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const resetFormHandler = () => {
    setFormSubmitted(false);
    setFocusInput(true);
    onFormReset();
  };

  return (
    <Box sx={{ marginBottom: '12px' }}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack spacing={1.5}>
            <Typography variant='caption' component='label' fontSize={'0.9rem'} marginLeft={1}>
              {t('login.writeYourPhoneNumber')} *
            </Typography>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={1}
              sx={{
                marginTop: '2px !important'
              }}
            >
              {!loading && (
                <PhoneNumberInputCountry
                  focusInput={focusInput}
                  formSubmitted={formSubmitted}
                  fieldName='username'
                />
              )}
              {formSubmitted && (
                <Box sx={{ flexShrink: 0 }}>
                  <IconButton onClick={resetFormHandler}>
                    <Edit fontSize='medium' />
                  </IconButton>
                </Box>
              )}
              {loading && (
                <Stack sx={{ width: '100%' }} flexDirection={'row'} justifyContent={'center'}>
                  <CircularProgress />
                </Stack>
              )}
            </Stack>
            {!formSubmitted && (
              <Button
                sx={{ marginBottom: '10px', padding: '8px 22px' }}
                variant='contained'
                disabled={loading}
                fullWidth
                type={'submit'}
              >
                {t('common.next')}
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default PhoneNumberForm;
