/* eslint-disable max-lines */
type CountryCode =
  | 'NO'
  | 'SE'
  | 'DK'
  | 'FI'
  | 'IS'
  | 'GB'
  | 'US'
  | 'CA'
  | 'DE'
  | 'UA'
  | 'FR'
  | 'ES'
  | 'IT'
  | 'PL'
  | 'NL'
  | 'BE'
  | 'AT'
  | 'CH'
  | 'PT'
  | 'CZ'
  | 'GR'
  | 'JP';

export const countryCodes: CountryCode[] = [
  'NO',
  'SE',
  'DK',
  'FI',
  'IS',
  'GB',
  'US',
  'CA',
  'DE',
  'UA',
  'FR',
  'ES',
  'IT',
  'PL',
  'NL',
  'BE',
  'AT',
  'CH',
  'PT',
  'CZ',
  'GR',
  'JP'
];

export type Continent = 'EU' | 'World';

export type CountryInfo = {
  phonePrefix: string;
  languageCode: string;
  phoneDigits: number[];
  country: string;
  continent: Continent;
};

export const COUNTRY_INFO: Record<CountryCode, CountryInfo> = {
  NO: {
    phonePrefix: '+47',
    languageCode: 'nb-NO',
    phoneDigits: [8],
    country: 'Norway',
    continent: 'EU'
  },
  SE: {
    phonePrefix: '+46',
    languageCode: 'sv-SE',
    phoneDigits: [9, 10],
    country: 'Sweden',
    continent: 'EU'
  },
  DK: {
    phonePrefix: '+45',
    languageCode: 'da-DK',
    phoneDigits: [8],
    country: 'Denmark',
    continent: 'EU'
  },
  FI: {
    phonePrefix: '+358',
    languageCode: 'fi-FI',
    phoneDigits: [5, 6, 7, 8],
    country: 'Finland',
    continent: 'EU'
  },
  IS: {
    phonePrefix: '+354',
    languageCode: 'is-IS',
    phoneDigits: [7],
    country: 'Iceland',
    continent: 'EU'
  },
  GB: {
    phonePrefix: '+44',
    languageCode: 'en-GB',
    phoneDigits: [10, 11],
    country: 'United Kingdom',
    continent: 'EU'
  },
  US: {
    phonePrefix: '+1',
    languageCode: 'en-US',
    phoneDigits: [10],
    country: 'United States',
    continent: 'World'
  },
  CA: {
    phonePrefix: '+1',
    languageCode: 'en-CA',
    phoneDigits: [10],
    country: 'Canada',
    continent: 'World'
  },
  DE: {
    phonePrefix: '+49',
    languageCode: 'de-DE',
    phoneDigits: [10, 11],
    country: 'Germany',
    continent: 'EU'
  },
  UA: {
    phonePrefix: '+380',
    languageCode: 'uk-UA',
    phoneDigits: [9],
    country: 'Ukraine',
    continent: 'EU'
  },
  FR: {
    phonePrefix: '+33',
    languageCode: 'fr-FR',
    phoneDigits: [9],
    country: 'France',
    continent: 'EU'
  },
  ES: {
    phonePrefix: '+34',
    languageCode: 'es-ES',
    phoneDigits: [9],
    country: 'Spain',
    continent: 'EU'
  },
  IT: {
    phonePrefix: '+39',
    languageCode: 'it-IT',
    phoneDigits: [10],
    country: 'Italy',
    continent: 'EU'
  },
  PL: {
    phonePrefix: '+48',
    languageCode: 'pl-PL',
    phoneDigits: [9],
    country: 'Poland',
    continent: 'EU'
  },
  NL: {
    phonePrefix: '+31',
    languageCode: 'nl-NL',
    phoneDigits: [9],
    country: 'Netherlands',
    continent: 'EU'
  },
  BE: {
    phonePrefix: '+32',
    languageCode: 'nl-BE',
    phoneDigits: [9],
    country: 'Belgium',
    continent: 'EU'
  },
  AT: {
    phonePrefix: '+43',
    languageCode: 'de-AT',
    phoneDigits: [10],
    country: 'Austria',
    continent: 'EU'
  },
  CH: {
    phonePrefix: '+41',
    languageCode: 'de-CH',
    phoneDigits: [9],
    country: 'Switzerland',
    continent: 'EU'
  },
  PT: {
    phonePrefix: '+351',
    languageCode: 'pt-PT',
    phoneDigits: [9],
    country: 'Portugal',
    continent: 'EU'
  },
  CZ: {
    phonePrefix: '+420',
    languageCode: 'cs-CZ',
    phoneDigits: [9],
    country: 'Czech Republic',
    continent: 'EU'
  },
  GR: {
    phonePrefix: '+30',
    languageCode: 'el-GR',
    phoneDigits: [10],
    country: 'Greece',
    continent: 'EU'
  },
  JP: {
    phonePrefix: '+81',
    languageCode: 'ja-JP',
    phoneDigits: [10],
    country: 'Japan',
    continent: 'World'
  }
};

export default CountryCode;
