import Skin from 'types/Skin';

const ytresogn: Skin = {
  COLORS: {
    PRIMARY: '#cc0000',
    PRIMARY_DARK: '#790404',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#790404',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/09/ytresogn-icon.png',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/09/ytresogn-logo.png',
  },
};

export default ytresogn;