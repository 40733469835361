import Skin from 'types/Skin';

const sirdalmedia: Skin = {
  COLORS: {
    PRIMARY: '#8a267e',
    PRIMARY_DARK: '#29245c',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#29245c',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/09/sirdalmedia-icon.svg',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/09/sirdalmedia-logo-white.svg',
  },
};

export default sirdalmedia;