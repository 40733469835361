import { Button, Typography } from '@mui/material';
import PrimaryCard from 'components/PrimaryCard/PrimaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Subscription from 'types/Subscription';
import Campaign from 'types/Campaign';
import FlexBox from 'components/FlexBox/FlexBox';
import { useNavigate } from 'react-router';
import routes from 'navigation/routes';
import getReadbleDuration from 'utils/_getReadbleDuration';
import theme from 'styles/theme';

type SelectedSubscriptionPaymentProps = {
  subscription: Subscription;
  campaign?: Campaign;
  editDisabled?: boolean;
  hideEdit?: boolean;
};

const SelectedSubscriptionPayment: FC<SelectedSubscriptionPaymentProps> = ({
  subscription,
  campaign,
  editDisabled,
  hideEdit
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { price, name, duration } = subscription || {};

  return (
    <PrimaryCard variant={'outlined'} sx={{ width: '100%', boxSizing: 'border-box' }}>
      <FlexBox justifyContent='space-between' alignItems='center'>
        <Typography>{t('subscription.selected')}</Typography>
        {!hideEdit && (
          <Button
            disabled={editDisabled}
            onClick={() => navigate({ pathname: routes.ORDER_SUBSCRIPTION_SELECT.path })}
          >
            {t('common.change')}
          </Button>
        )}
      </FlexBox>
      <Typography className={'subscription-name'} variant={'h5'}>
        {name}
      </Typography>
      {campaign && (
        <Typography variant='h6'>{`${t('common.campaign')}: ${campaign?.name}`}</Typography>
      )}
      <Typography variant={'h6'}>
        {campaign ? t('common.renewal') : t('common.price')}: {price} kr /{' '}
        {getReadbleDuration(duration)}
      </Typography>
      <Typography
        sx={{
          mt: 1,
          fontStyle: 'italic',
          color: theme.palette.text.secondary
        }}
      >
        {t('subscription.renewalInfo')}
      </Typography>
      {/*  </Stack> */}
    </PrimaryCard>
  );
};

export default SelectedSubscriptionPayment;
