import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userAuthService = {
  signIn: (phone: string, password: string) => {
    return axios.post('user-auth/sign-in', {
      phone: phone,
      password: password
    });
  },
  createAuthCode: () => {
    return axios.post('user-auth/create-auth-code');
  },
  changePassword: (newPassword: string, oldPassword: string) => {
    return axios.post('user-auth/change-password', {
      newPassword: newPassword,
      oldPassword: oldPassword
    });
  },
  forgotPassword: (email: string, fromApp: string) => {
    return axios.post('user-auth/request-password-reset', {
      email: email,
      fromApp: fromApp
    });
  },
  setNewPassword: (newPassword: string, token: string) => {
    return axios.post('user-auth/reset-password', {
      password: newPassword,
      token: token
    });
  },
  setNewPasswordAndPhone: (newPassword: string, token: string, phone: string) => {
    return axios.post('user-auth/set-password-and-phone', {
      password: newPassword,
      token: token,
      phone: phone
    });
  },
  changePhoneNumberRequest: (phone: string) => {
    return axios.post('user-auth/change-phone-number-request', {
      phone: phone
    });
  },
  changePhoneNumber: (newPhoneNumber: string) => {
    return axios.post('user-auth/change-phone-number', {
      phone: newPhoneNumber
    });
  },
  phoneNumberExist: (phone: string) => {
    return axios.post('user/exist', {
      phone: phone
    });
  },
  changePostCode: (newPostCode: string) => {
    return axios.post('user-auth/change-post-code', {
      postCode: newPostCode
    });
  },
  signOut: () => {
    return axios.post('user-auth/sign-out');
  }
};

export default userAuthService;
