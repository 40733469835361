import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import PhoneNumberForm from '../PhoneNumberForm';
import PasswordForm, { ExtraRegisterFields } from '../PasswordForm';
import { useLocation, useNavigate } from 'react-router';
import routes from 'navigation/routes';
import { Box, SxProps } from '@mui/system';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContentTitle from 'components/PaperContentTitle/PaperContentTitle';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import OrderSubscriptionState from 'types/OrderSubscriptionState';
import { formatPhoneNumber } from 'utils/_formatPhoneNumber';

type LoginFromProps = {
  borderBox?: boolean;
};

const LoginForm: FC<LoginFromProps> = ({ borderBox }) => {
  const [isNewUser, setIsNewUser] = useState(null);
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
  const [preSentData, setPreSentData] = useState({
    phoneNumber: null,
    phoneCountryCode: 'NO'
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosAlert = useAxiosAlert();
  const [loading, setLoading] = useState<boolean>();
  const locationState = useLocation().state as OrderSubscriptionState;
  const hasLocationState = !!locationState;

  const { signIn, createNewUser } = useUser();

  const handlePasswordSubmit = async (passwordFields: ExtraRegisterFields) => {
    setLoading(true);
    try {
      const { password, name } = passwordFields;
      if (isNewUser && phoneNumberSubmitted) {
        await createNewUser(preSentData, name, password, passwordFields);
      } else {
        await signIn(preSentData.phoneNumber, password, hasLocationState);
      }
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const forgotPasswordHanlder = () => {
    navigate(routes.FORGOT_PASSWORD.path);
  };

  const styledPaperCard: SxProps = {
    padding: borderBox ? '16px' : '4px',
    width: '100%',
    border: borderBox ? null : 'none'
  };

  const getTitle = () => {
    if (isNewUser) {
      return t('login.createUser');
    }

    if (phoneNumberSubmitted && !isNewUser) {
      return t('login.signIn');
    }

    return t('login.signInOrCreateUser');
  };

  const isFacebookAppBrowser = navigator.userAgent.includes('FBAN');

  return (
    <Box className={'login-form'}>
      <PaperCard variant='outlined' sx={styledPaperCard}>
        <PaperContentTitle>{getTitle()}</PaperContentTitle>
        <div className={'fields'}>
          {isFacebookAppBrowser && !isNewUser && (
            <Alert severity={'warning'} sx={{ marginBottom: '16px' }}>
              <Typography
                variant='h6'
                sx={{ position: 'relative', bottom: '5px', fontWeight: '700' }}
              >
                {t('alerts.facebookLoginWarningHead')}
              </Typography>
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {t('alerts.facebookLoginWarningBody')}
              </Typography>
            </Alert>
          )}
          <PhoneNumberForm
            onFormReset={() => {
              setPhoneNumberSubmitted(false);
              setIsNewUser(null);
              setPreSentData({ phoneNumber: null, phoneCountryCode: 'NO' });
            }}
            onSubmit={(isNewUser, phoneNumber) => {
              setPreSentData(phoneNumber);
              setPhoneNumberSubmitted(true);
              setIsNewUser(isNewUser);
              if (!isNewUser) {
                // Focus password input
                setTimeout(() => {
                  document.getElementById('password-input').focus();
                }, 100);
              }
            }}
          />
          {isNewUser && (
            <Alert severity={'info'} sx={{ marginBottom: '16px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('alerts.cantFindNumberCreateUser', {
                    phone: formatPhoneNumber(preSentData.phoneNumber as string)
                  })
                }}
              />
            </Alert>
          )}
          {phoneNumberSubmitted && (
            <PasswordForm loading={loading} isNewUser={isNewUser} onSubmit={handlePasswordSubmit} />
          )}
          {loading && (
            <Stack sx={{ width: '100%' }} flexDirection={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
        </div>
        {(!phoneNumberSubmitted || !isNewUser) && (
          <div className='actions'>
            <Button fullWidth onClick={forgotPasswordHanlder}>
              {t('common.forgotPassword')}
            </Button>
          </div>
        )}
      </PaperCard>
    </Box>
  );
};

export default LoginForm;
