import { FC, useEffect, useState } from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import {
  AddEmailDialog,
  ChangeNameDialog,
  ChangePasswordDialog,
  ChangePhoneNumberDialog,
  PageContainer,
  ProfileInfoItem
} from 'components';
import UserSubscriptionInfo from 'components/UserSubscriptionInfo/UserSubscriptionInfo';

import PaperHeader from 'components/PaperHeader/PaperHeader';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperCard from 'components/PaperCard/PaperCard';
import theme from 'styles/theme';
import ChangePaymentSolutionDialog from 'components/ChangePaymentSolutionDialog/ChangePaymentSolutionDialog';
import ChangeEmailDialog from './ChangeEmailDialog/ChangeEmailDialog';
import CanceledUserSubscriptionInfo from 'components/CanceledUserSubscriptionInfo/CanceledUserSubscriptionInfo';
import Notifications from './Notifications/Notifications';
import ChangeAddressDialog from 'components/ChangeAddressDialog/ChangeAddressDialog';
import ProfileAlerts from './ProfileAlerts/ProfileAlerts';
import { Stack } from '@mui/system';
import useIsMobile from 'hooks/useIsMobile';
import AddPostCodeDialog from './AddPostCodeDialog/AddPostCodeDialog';
import { formatPhoneNumber } from 'utils/_formatPhoneNumber';

const Profile: FC = () => {
  const { user } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [modal, setModal] = useState<
    | 'changePassword'
    | 'changePhoneNumber'
    | 'changeName'
    | 'addEmail'
    | 'changeAddress'
    | 'changePaymentSolution'
    | 'changeEmail'
    | 'addPostCode'
    | null
  >(null);

  const settings = useSelector((state: RootState) => state.settings);
  const topImage = settings.find((s) => s.key === 'TopImage')?.value || '';

  const closeModal = () => {
    setModal(null);
  };

  useEffect(() => {
    // Get parameter askForPostCode from url
    const urlParams = new URLSearchParams(window.location.search);
    const askForPostCodeParam = urlParams.get('askForPostCode');
    let askForPostCode = false;
    if (
      askForPostCodeParam &&
      (askForPostCodeParam.toLocaleLowerCase() === 'true' || askForPostCodeParam === '1')
    ) {
      if (!user.userAddress || !user.userAddress.postCode) {
        askForPostCode = true;
      }
    }
    const askForEmail = !user.email;
    if (askForPostCode) {
      setModal('addPostCode');
    } else if (askForEmail) {
      setModal('addEmail');
    }
  }, [user]);

  const styledPageContainer: SxProps = {
    background: '#efefef',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '40px'
    }
  };

  return (
    <>
      <PageContainer
        sx={styledPageContainer}
        sxPageContent={{
          padding: '16px 0 16px 0 !important'
        }}
        centerHorizontal
      >
        <Box
          sx={{
            width: '100%',
            marginTop: '-20px',
            height: '30vh',
            overflow: 'hidden',
            position: 'relative',

            [theme.breakpoints.up('md')]: {
              paddingBottom: '50px'
            }
          }}
        >
          <Box
            sx={{
              position: 'relative',
              backgroundImage: `url(${topImage})`,
              backgroundPosition: 'center',
              backgroundSize: '63% auto',
              marginBottom: '-50px',
              left: '-30%',
              width: '160%',
              height: '30vh',
              borderBottomLeftRadius: '50% 150px',
              borderBottomRightRadius: '50% 150px',
              [theme.breakpoints.down('md')]: {
                borderBottomLeftRadius: '50% 15px',
                borderBottomRightRadius: '50% 15px',
                backgroundSize: 'cover',
                width: '100%',
                left: 0
              }
            }}
          ></Box>
        </Box>
        <Notifications />
        <Stack
          gap={2}
          sx={{
            padding: '0 16px',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
              width: '100%'
            }
          }}
        >
          <ProfileAlerts onAlertActionClick={() => setModal('changePaymentSolution')} />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            alignItems={isMobile ? 'center' : 'flex-start'}
            gap={2}
            sx={{ width: isMobile ? '100%' : 'auto' }}
            boxSizing={'border-box'}
          >
            <PaperCard sx={{ maxWidth: '440px' }}>
              <PaperHeader>
                <Typography variant='h4'>{t('profile.myProfile')}</Typography>
              </PaperHeader>
              <PaperContent>
                <ProfileInfoItem
                  label={t('common.name')}
                  text={user?.name}
                  action={
                    <div onClick={() => setModal('changeName')}>
                      <Button color='secondary' variant={'outlined'} size='small'>
                        {user?.name ? t('common.changeName') : t('common.changeName')}
                      </Button>
                    </div>
                  }
                />
                <ProfileInfoItem
                  label={t('common.phone')}
                  text={formatPhoneNumber(user?.phone)}
                  action={
                    <div onClick={() => setModal('changePhoneNumber')}>
                      <Button color='secondary' variant={'outlined'} size='small'>
                        {t('common.changePhoneNumber')}
                      </Button>
                    </div>
                  }
                />
                <ProfileInfoItem
                  label={t('common.email')}
                  text={user?.email || t('common.notAdded')}
                  action={
                    <div
                      onClick={() => {
                        user.email ? setModal('changeEmail') : setModal('addEmail');
                      }}
                    >
                      <Button color='secondary' variant={'outlined'} size='small'>
                        {user?.email ? t('common.changeEmail') : t('common.addEmail')}
                      </Button>
                    </div>
                  }
                />
                <ProfileInfoItem
                  label={t('common.address')}
                  style={{ whiteSpace: 'pre-line' }}
                  text={
                    user?.userAddress
                      ? (user.userAddress?.address ?? '--') +
                        '\n' +
                        (user.userAddress?.postCode ?? '--') +
                        ' ' +
                        (user.userAddress?.postOffice ?? '')
                      : t('common.notAdded')
                  }
                  action={
                    <div onClick={() => setModal('changeAddress')}>
                      <Button color='secondary' variant={'outlined'} size='small'>
                        {user?.userAddress ? t('common.changeAddress') : t('common.addAddress')}
                      </Button>
                    </div>
                  }
                />

                <ProfileInfoItem
                  label={t('password.password')}
                  text={'******'}
                  action={
                    <div onClick={() => setModal('changePassword')}>
                      <Button color='secondary' variant={'outlined'} size='small'>
                        {t('common.changePassword')}
                      </Button>
                    </div>
                  }
                />
              </PaperContent>
            </PaperCard>
            <PaperCard sx={{ maxWidth: '440px' }}>
              <PaperHeader>
                <Typography variant='h4'>{t('common.subscription')}</Typography>
              </PaperHeader>
              <PaperContent>
                {(user?.hasActiveUserSubscription || !user?.userSubscription) && (
                  <UserSubscriptionInfo />
                )}
                {user?.userSubscription && user?.userSubscription?.status === 'CANCELED' && (
                  <CanceledUserSubscriptionInfo />
                )}
              </PaperContent>
            </PaperCard>
          </Stack>
        </Stack>
      </PageContainer>
      {modal === 'changePassword' && (
        <ChangePasswordDialog onConfirm={closeModal} onCancel={closeModal} />
      )}
      {modal === 'changePhoneNumber' && (
        <ChangePhoneNumberDialog
          phone={user.phone}
          onCancel={closeModal}
          onSuccess={closeModal}
          phoneCountryCode={user.phoneCountryCode}
        />
      )}
      {modal === 'changeName' && (
        <ChangeNameDialog currentName={user.name} onCancel={closeModal} onConfirm={closeModal} />
      )}
      {modal === 'addEmail' && <AddEmailDialog onCancel={closeModal} onConfirm={closeModal} />}
      {modal === 'changeAddress' && (
        <ChangeAddressDialog user={user} onCancel={closeModal} onSuccess={closeModal} />
      )}
      {modal === 'changePaymentSolution' && (
        <ChangePaymentSolutionDialog
          onCancel={closeModal}
          userSubscription={user?.userSubscription}
        />
      )}
      {modal === 'changeEmail' && (
        <ChangeEmailDialog email={user.email} onCancel={closeModal} onSuccess={closeModal} />
      )}
      {modal === 'addPostCode' && (
        <AddPostCodeDialog onCancel={closeModal} onSuccess={closeModal} />
      )}
    </>
  );
};

export default Profile;
