import CountryCode, { countryCodes } from 'types/CountryCode';

const getCountryCodeFlag = (countryCode: CountryCode) => {
  const flag = countryCodes.map((code) => {
    if (countryCode === code) {
      const offset = 127397;
      const flag = code
        .toUpperCase()
        .split('')
        .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
        .join('');

      return `${flag} ${code}`;
    }
  });

  return flag;
};

export const getCountryFlag = (countryCode: CountryCode) => {
  const flag = countryCodes.map((code) => {
    if (countryCode === code) {
      const offset = 127397;
      const flag = code
        .toUpperCase()
        .split('')
        .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
        .join('');

      return flag;
    }
  });

  return flag;
};

export default getCountryCodeFlag;
