import Skin from 'types/Skin';

const sovesten: Skin = {
  COLORS: {
    PRIMARY: '#006ba3',
    PRIMARY_DARK: '#0e5579',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#0e5579',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/09/sovesten-icon.svg',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/09/sovesten-logo-white.svg',
  },
};

export default sovesten;