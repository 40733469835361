import { FC, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { validate } from 'utils';
import { FormProvider, useForm } from 'react-hook-form';
import { FormDialog } from 'components';
import useUser from 'hooks/useUser';
import PhoneNumberInputCountry from 'components/PhoneNumber/PhoneNumberCountry';

type ChangePhoneNumberDialogProps = {
  phone?: string;
  phoneCountryCode?: string;
  onCancel: () => void;
  onSuccess: () => void;
};

type ChangePhoneNumberFields = {
  phone: string;
  phoneCountryCode: string;
  password: string;
};

const ChangePhoneNumberDialog: FC<ChangePhoneNumberDialogProps> = ({
  phone,
  phoneCountryCode,
  onCancel,
  onSuccess
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<ChangePhoneNumberFields>({
    defaultValues: {
      phone: phone?.toString() || '',
      password: '',
      phoneCountryCode: phoneCountryCode || 'NO'
    }
  });

  const { changePhoneNumber } = useUser();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: ChangePhoneNumberFields) => {
    const { phone, phoneCountryCode, password } = data;
    setLoading(true);
    try {
      await changePhoneNumber(phone.split(' ').join(''), phoneCountryCode, password);
      onSuccess();
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onCancel={onCancel}
      onSubmit={formMethods.handleSubmit(onSubmit)}
      title={t('common.changePhoneNumber')}
      submitText={t('common.change')}
      loading={loading}
      sxHeader={{ fontSize: '1.8rem' }}
      sxContent={{ maxWidth: '350px' }}
    >
      <Stack spacing={2}>
        <FormProvider {...formMethods}>
          <PhoneNumberInputCountry focusInput={true} formSubmitted={false} fieldName='phone' />

          <Typography sx={{ marginBottom: '16px', fontStyle: 'italic', fontSize: '0.8em' }}>
            {t('profile.toChangePhoneNumberYouMustProvidePassword')}
          </Typography>
          <TextField
            type='password'
            label={t('password.password')}
            {...formMethods.register('password', {
              required: true,
              validate: validate.password
            })}
            variant='outlined'
            error={formMethods.formState.errors.password ? true : false}
            fullWidth
            helperText={
              formMethods.formState.errors.password
                ? formMethods.formState.errors.password.message
                : ''
            }
          />
        </FormProvider>
      </Stack>
    </FormDialog>
  );
};

export default ChangePhoneNumberDialog;
