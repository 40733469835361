import axios from 'axios';
import { ExtraRegisterFields } from 'pages/Login/components/PasswordForm';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userService = {
  createUser: (
    preSentData: {
      phoneNumber: string;
      phoneCountryCode: string;
    },
    name: string,
    password: string,
    extraFields: ExtraRegisterFields
  ) => {
    const { email, ...userAddress } = extraFields;

    return axios.post('user', {
      phone: preSentData.phoneNumber,
      name: name,
      password: password,
      email: email,
      phoneCountryCode: preSentData.phoneCountryCode,
      userAddress: userAddress
    });
  },
  phoneNumberExist: (phone: string, phoneCountryCode: string) => {
    return axios.post('user/exist', {
      phone: phone,
      phoneCountryCode: phoneCountryCode
    });
  }
};

export default userService;
